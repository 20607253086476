import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import NewsTable from '../news/NewsTable';
import MarketOverview from '../widgets/MarketOverview';
import { NewsItem, Article } from '../../types/news';

const HomePage: React.FC = () => {
    const { api } = useAuth();
    const [news, setNews] = useState<NewsItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchNews = async (pageNum: number) => {
        try {
            const response = await api.get('/api/articles/public', {
                params: {
                    page: pageNum,
                    limit: 20
                }
            });
            
            if (!response.data || !Array.isArray(response.data.articles)) {
                console.error('Invalid response format:', response.data);
                setNews([]);
                setHasMore(false);
                return;
            }

            const transformedNews: NewsItem[] = response.data.articles.map((article: Article) => ({
                id: article.id.toString(),
                timestamp: article.published_at,
                publisher: {
                    name: article.publisher?.name || 'Unknown Source',
                    logo_url: article.publisher?.logo_url || null
                },
                headline: article.title,
                url: article.url,
                freshness: calculateFreshness(article.published_at),
                sentiment: 'neutral',
                coins: Array.isArray(article.tags) ? article.tags.map(tag => tag.name) : [],
                aiSummary: article.url
            }));

            if (pageNum === 1) {
                setNews(transformedNews);
            } else {
                setNews(prev => [...prev, ...transformedNews]);
            }
            setHasMore(response.data.hasMore);
        } catch (error) {
            console.error('Failed to fetch news:', error);
            setNews([]);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNews(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchNews(nextPage);
    };

    const calculateFreshness = (publishedAt: string): number => {
        const now = new Date().getTime();
        const published = new Date(publishedAt).getTime();
        const hoursSincePublished = (now - published) / (1000 * 60 * 60);
        // Freshness score decreases by 10 points every hour, minimum 0
        return Math.max(0, 100 - (hoursSincePublished * 10));
    };

    return (
        <div className="space-y-6">
            {/* Market Overview Widget */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <div className="lg:col-span-2">
                    <MarketOverview />
                </div>
                <div className="bg-white rounded-lg shadow p-6">
                    <h2 className="text-xl font-semibold mb-4">Trend Accuracy</h2>
                    <div className="flex justify-center">
                        <div className="relative w-48 h-48">
                            <div className="absolute inset-0 flex items-center justify-center">
                                <span className="text-2xl font-bold">80%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* News Table Section */}
            <div className="bg-white rounded-lg shadow">
                <div className="p-6">
                    <h2 className="text-xl font-semibold mb-4">Latest News</h2>
                    <NewsTable 
                        news={news} 
                        loading={loading} 
                        onLoadMore={handleLoadMore}
                        hasMore={hasMore}
                    />
                </div>
            </div>
        </div>
    );
};

export default HomePage; 