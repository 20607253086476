import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface Tag {
    id: number;
    name: string;
}

const TagManagement: React.FC = () => {
    const { api } = useAuth();
    const [tags, setTags] = useState<Tag[]>([]);
    const [newTagName, setNewTagName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const fetchTags = async () => {
        try {
            const response = await api.get('/api/tags');
            setTags(response.data);
        } catch (error) {
            console.error('Failed to fetch tags:', error);
            setError('Failed to fetch tags');
        }
    };

    useEffect(() => {
        fetchTags();
    }, []);

    const handleAddTag = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await api.post('/api/tags', { name: newTagName });
            setNewTagName('');
            setSuccess('Tag added successfully');
            fetchTags();
            setTimeout(() => setSuccess(''), 3000);
        } catch (error) {
            console.error('Failed to add tag:', error);
            setError('Failed to add tag');
            setTimeout(() => setError(''), 3000);
        }
    };

    const handleDeleteTag = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this tag?')) {
            return;
        }
        try {
            await api.delete(`/api/tags/${id}`);
            setSuccess('Tag deleted successfully');
            fetchTags();
            setTimeout(() => setSuccess(''), 3000);
        } catch (error) {
            console.error('Failed to delete tag:', error);
            setError('Failed to delete tag');
            setTimeout(() => setError(''), 3000);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-6">Tag Management</h1>

            {error && (
                <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                    {error}
                </div>
            )}

            {success && (
                <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
                    {success}
                </div>
            )}

            <form onSubmit={handleAddTag} className="mb-6">
                <div className="flex gap-2">
                    <input
                        type="text"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        placeholder="Enter tag name"
                        className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                    />
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Add Tag
                    </button>
                </div>
            </form>

            <div className="bg-white rounded-lg shadow">
                <div className="grid grid-cols-1 gap-2 p-4">
                    {tags.map((tag) => (
                        <div
                            key={tag.id}
                            className="flex items-center justify-between p-2 hover:bg-gray-50"
                        >
                            <span>{tag.name}</span>
                            <button
                                onClick={() => handleDeleteTag(tag.id)}
                                className="text-red-600 hover:text-red-800"
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TagManagement; 