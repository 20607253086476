import React from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
    return (
        <nav className="bg-white shadow-sm">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center h-16">
                    <Link to="/" className="font-bold text-xl text-gray-800">
                       Coinscoop
                    </Link>
                    <div className="flex space-x-4">
                        <Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link>
                        <Link to="/news" className="text-gray-600 hover:text-gray-900">News</Link>
                        {/* <Link to="/admin" className="text-gray-600 hover:text-gray-900">Admin</Link> */}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar; 