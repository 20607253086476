import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PublicLayout from './components/layouts/PublicLayout';
import HomePage from './components/pages/HomePage';
import PublisherManagement from './components/PublisherManagement';
import Dashboard from './components/Dashboard';
import ArticleManagement from './components/ArticleManagement';
import ArticlesManagement from './components/ArticlesManagement';
import TagManagement from './components/TagManagement';
import Login from './components/Login';
import ProtectedLayout from './components/ProtectedLayout';
import UserManagement from './components/UserManagement';
import ArticleCards from './components/ArticleCards';
import ActivityLogs from './components/ActivityLogs';

const App: React.FC = () => {
    const navigation = [
        { name: 'Articles', href: '/articles' },
        { 
            name: 'Articles Management', 
            href: '/admin/articles_management',
            requiresAdmin: true 
        }
    ]

    return (
        <div className="App">
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/news" element={<HomePage />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/admin" element={<ProtectedLayout navigation={navigation} />}>
                    <Route index element={<Dashboard />} />
                    <Route path="publishers" element={<PublisherManagement />} />
                    <Route path="articles" element={<ArticleManagement />} />
                    <Route path="articles_management" element={<ArticlesManagement />} />
                    <Route path="tags" element={<TagManagement />} />
                    <Route path="users" element={<UserManagement />} />
                    <Route path="logs" element={<ActivityLogs />} />
                </Route>
                <Route path="/articles" element={<ArticleCards />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
    );
};

export default App;
