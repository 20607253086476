import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';

interface Article {
  id: number;
  title: string;
  content: string;
  url: string;
  image_url: string | null;
  published_at: string;
  featured: boolean;
  score: number | null;
  sentiment: string | null;
  overview: string | null;
  publisher: {
    name: string;
    logo_url: string | null;
  };
  tags: Array<{ id: number; name: string }>;
}

interface ArticleDetailModalProps {
  article: Article | null;
  onClose: () => void;
  onDelete: (id: number) => void;
}

const ArticleDetailModal: React.FC<ArticleDetailModalProps> = ({ article, onClose, onDelete }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  if (!article) return null;

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      await onDelete(article.id);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-xl font-bold">{article.title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <span className="sr-only">Close</span>
            ×
          </button>
        </div>
        
        <div className="space-y-4">
          {article.image_url && (
            <img src={article.image_url} alt={article.title} className="w-full h-48 object-cover rounded" />
          )}
          
          <div className="flex items-center space-x-2">
            <span className="font-semibold">Publisher:</span>
            <span>{article.publisher?.name || 'Unknown Publisher'}</span>
          </div>
          
          <div className="flex items-center space-x-2">
            <span className="font-semibold">Published:</span>
            <span>{format(new Date(article.published_at), 'PPP')}</span>
          </div>
          
          {article.tags && article.tags.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {article.tags.map(tag => (
                <span key={tag.id} className="px-2 py-1 bg-gray-100 rounded-full text-sm">
                  {tag.name}
                </span>
              ))}
            </div>
          )}

          {article.score != null && (
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Score:</span>
              <span>{Number(article.score).toFixed(2)}</span>
            </div>
          )}

          {article.sentiment && (
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Sentiment:</span>
              <span>{article.sentiment}</span>
            </div>
          )}

          {article.overview && (
            <div className="mt-4">
              <h3 className="font-semibold mb-2">Overview:</h3>
              <div className="prose max-w-none">{article.overview}</div>
            </div>
          )}
          
          <div className="mt-4">
            <h3 className="font-semibold mb-2">Content:</h3>
            {article.content ? (
              <div>
                <div className={`prose max-w-none ${!showFullContent ? 'line-clamp-3' : ''}`}>
                  {showFullContent ? (
                    <div dangerouslySetInnerHTML={{ __html: article.content }} />
                  ) : (
                    article.content.replace(/<[^>]*>/g, '').substring(0, 200) + '...'
                  )}
                </div>
                <button
                  onClick={() => setShowFullContent(!showFullContent)}
                  className="text-blue-600 hover:underline mt-2"
                >
                  {showFullContent ? 'Show Less' : 'Show Full Content'}
                </button>
              </div>
            ) : (
              <div className="text-gray-500 italic">No content available</div>
            )}
          </div>
          
          <div className="flex justify-between items-center mt-6">
            <a 
              href={article.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              View Original Article
            </a>
            <button
              onClick={handleDelete}
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
            >
              Delete Article
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ArticlesManagement: React.FC = () => {
  const { api } = useAuth();
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    publisher: '',
    tag: '',
    dateFrom: '',
    dateTo: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchArticles();
  }, [filters]);

  const fetchArticles = async () => {
    try {
      const params = new URLSearchParams();
      if (filters.publisher) params.append('publisher', filters.publisher);
      if (filters.tag) params.append('tag', filters.tag);
      if (filters.dateFrom) params.append('dateFrom', filters.dateFrom);
      if (filters.dateTo) params.append('dateTo', filters.dateTo);

      const response = await api.get(`/api/articles?${params.toString()}`);
      setArticles(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError('Failed to fetch articles. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`/api/articles/${id}`);
      setArticles(articles.filter(article => article.id !== id));
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  const handleToggleFeatured = async (id: number, featured: boolean) => {
    try {
      await api.patch(`/articles/${id}/featured`, { featured: !featured });
      setArticles(articles.map(article => 
        article.id === id ? { ...article, featured: !featured } : article
      ));
    } catch (error) {
      console.error('Error updating article:', error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Articles Management</h1>
      
      {/* Filters */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
        <input
          type="text"
          placeholder="Filter by publisher"
          className="border rounded p-2"
          value={filters.publisher}
          onChange={e => setFilters({ ...filters, publisher: e.target.value })}
        />
        <input
          type="text"
          placeholder="Filter by tag"
          className="border rounded p-2"
          value={filters.tag}
          onChange={e => setFilters({ ...filters, tag: e.target.value })}
        />
        <input
          type="date"
          className="border rounded p-2"
          value={filters.dateFrom}
          onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
        />
        <input
          type="date"
          className="border rounded p-2"
          value={filters.dateTo}
          onChange={e => setFilters({ ...filters, dateTo: e.target.value })}
        />
      </div>

      {/* Articles Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded-lg">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Publisher</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tags</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Published</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sentiment</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Featured</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {articles.map(article => (
              <tr key={article.id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="text-sm font-medium text-gray-900">{article.title}</div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-500">
                    {article.publisher?.name || 'Unknown Publisher'}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-wrap gap-1">
                    {article.tags?.map(tag => (
                      <span key={tag.id} className="px-2 py-1 bg-gray-100 rounded-full text-xs">
                        {tag.name}
                      </span>
                    )) || 'No tags'}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-500">
                    {format(new Date(article.published_at), 'PPP HH:mm')}
                  </div>
                </td>
                <td className="px-6 py-4">
                  {article.score != null ? (
                    <div className="text-sm text-gray-500">
                      {Number(article.score).toFixed(2)}
                    </div>
                  ) : (
                    <div className="text-sm text-gray-400">-</div>
                  )}
                </td>
                <td className="px-6 py-4">
                  {article.sentiment ? (
                    <div className="text-sm text-gray-500">{article.sentiment}</div>
                  ) : (
                    <div className="text-sm text-gray-400">-</div>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  <button
                    onClick={() => handleToggleFeatured(article.id, article.featured || false)}
                    className={`px-3 py-1 rounded-full text-xs ${
                      article.featured 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {article.featured ? 'Featured' : 'Not Featured'}
                  </button>
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => setSelectedArticle(article)}
                    className="text-blue-600 hover:text-blue-900"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedArticle && (
        <ArticleDetailModal
          article={selectedArticle}
          onClose={() => setSelectedArticle(null)}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default ArticlesManagement; 