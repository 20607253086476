import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface CronSettings {
    enabled: boolean;
    interval: number;
    last_run: string | null;
}

const Dashboard: React.FC = () => {
    const { api } = useAuth();
    const [cronSettings, setCronSettings] = useState<CronSettings | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCronSettings();
    }, []);

    const fetchCronSettings = async () => {
        try {
            const response = await api.get('/api/settings/cron');
            setCronSettings(response.data);
        } catch (error) {
            console.error('Failed to fetch cron settings:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCronToggle = async () => {
        if (!cronSettings) return;
        
        try {
            const response = await api.put('/api/settings/cron', {
                enabled: !cronSettings.enabled,
                interval: cronSettings.interval
            });
            setCronSettings(response.data);
        } catch (error) {
            console.error('Failed to update cron settings:', error);
        }
    };

    const handleIntervalChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (!cronSettings) return;

        const newInterval = parseInt(event.target.value, 10);
        try {
            const response = await api.put('/api/settings/cron', {
                enabled: cronSettings.enabled,
                interval: newInterval
            });
            setCronSettings(response.data);
        } catch (error) {
            console.error('Failed to update cron settings:', error);
        }
    };

    return (
        <div>
            <h1 className="text-2xl font-bold mb-6">Dashboard</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-gray-500">Cron Job Settings</h2>
                    <div className="mb-4">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={cronSettings?.enabled}
                                onChange={handleCronToggle}
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                    </div>
                    
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Fetch Interval</label>
                        <select
                            value={cronSettings?.interval}
                            onChange={handleIntervalChange}
                            className="w-full p-2 border rounded"
                            disabled={!cronSettings?.enabled}
                        >
                            <option value={600}>10 minutes</option>
                            <option value={900}>15 minutes</option>
                            <option value={1800}>30 minutes</option>
                            <option value={3600}>1 hour</option>
                            <option value={7200}>2 hours</option>
                        </select>
                    </div>

                    {cronSettings?.last_run && (
                        <div className="text-sm text-gray-600">
                            Last run: {new Date(cronSettings.last_run).toLocaleString()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dashboard; 