// src/components/PublisherManagement.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import Modal from './Modal';

interface Publisher {
    id: number;
    name: string;
    rss_url: string;
    logo_url: string;
    description: string;
    active: boolean;
    lastFetched?: string;
    content_fetch_type: 'rss' | 'api' | null;
    content_rss_tag: string;
    content_api_key: string;
}

const PublisherManagement: React.FC = () => {
    const { api } = useAuth();
    const [publishers, setPublishers] = useState<Publisher[]>([]);
    const [selectedPublisher, setSelectedPublisher] = useState<Publisher | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        rss_url: '',
        logo_url: '',
        description: '',
        active: true,
        content_fetch_type: null as 'rss' | 'api' | null,
        content_rss_tag: '',
        content_api_key: ''
    });
    const [uploading, setUploading] = useState(false);
    const [fetchingFeeds, setFetchingFeeds] = useState<{[key: number]: boolean}>({});
    const [fetchingAll, setFetchingAll] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchPublishers = async () => {
        try {
            const response = await api.get('/api/publishers');
            setPublishers(response.data);
        } catch (error) {
            console.error('Failed to fetch publishers:', error);
        }
    };

    useEffect(() => {
        fetchPublishers();
    }, []);

    const handleEdit = (publisher: Publisher) => {
        setSelectedPublisher(publisher);
        setFormData({
            name: publisher.name,
            rss_url: publisher.rss_url,
            logo_url: publisher.logo_url || '',
            description: publisher.description || '',
            active: publisher.active,
            content_fetch_type: publisher.content_fetch_type,
            content_rss_tag: publisher.content_rss_tag || '',
            content_api_key: publisher.content_api_key || ''
        });
        setIsEditing(true);
        setIsModalOpen(true);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const updateData = {
                ...formData,
                content_fetch_type: formData.content_fetch_type || null,
                content_rss_tag: formData.content_fetch_type === 'rss' ? formData.content_rss_tag : null,
                content_api_key: formData.content_fetch_type === 'api' ? formData.content_api_key : null
            };

            console.log('Submitting data:', updateData); // Debug log

            if (selectedPublisher) {
                const response = await api.put(`/api/publishers/${selectedPublisher.id}`, updateData);
                console.log('Update response:', response.data); // Debug log
            } else {
                await api.post('/api/publishers', updateData);
            }
            
            await fetchPublishers();
            setIsModalOpen(false);
            setSelectedPublisher(null);
        } catch (error) {
            console.error('Error saving publisher:', error);
            alert('Failed to save publisher. Please check console for details.');
        }
    };

    const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        setUploading(true);
        const formData = new FormData();
        formData.append('logo', file);

        try {
            const response = await api.post('/api/publishers/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setFormData(prev => ({ ...prev, logo_url: response.data.url }));
        } catch (error) {
            console.error('Failed to upload logo:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFetchFeeds = async (publisherId: number) => {
        setFetchingFeeds(prev => ({ ...prev, [publisherId]: true }));
        try {
            const response = await api.post(`/api/publishers/${publisherId}/fetch`);
            console.log('Fetch response:', response.data);
            if (response.data.articlesCount) {
                console.log(`Fetched ${response.data.articlesCount} articles`);
                // Refresh publishers list to update lastFetched timestamp
                await fetchPublishers();
            }
        } catch (error) {
            console.error('Failed to fetch feeds:', error);
            // Add user feedback
            alert('Failed to fetch feeds. Please check the console for details.');
        } finally {
            setFetchingFeeds(prev => ({ ...prev, [publisherId]: false }));
        }
    };

    const handleFetchAllFeeds = async () => {
        setFetchingAll(true);
        try {
            const response = await api.post('/api/publishers/fetch-all');
            console.log('Fetch all response:', response.data);
            if (response.data.totalArticles) {
                console.log(`Fetched ${response.data.totalArticles} articles in total`);
                // Refresh publishers list to update lastFetched timestamps
                await fetchPublishers();
            }
        } catch (error) {
            console.error('Failed to fetch all feeds:', error);
            // Add user feedback
            alert('Failed to fetch all feeds. Please check the console for details.');
        } finally {
            setFetchingAll(false);
        }
    };

    return (
        <div className="p-6">
            <div className="flex justify-between mb-6">
                <h1 className="text-2xl font-bold">Publisher Management</h1>
                <button
                    onClick={() => {
                        setSelectedPublisher(null);
                        setFormData({
                            name: '',
                            rss_url: '',
                            logo_url: '',
                            description: '',
                            active: true,
                            content_fetch_type: null,
                            content_rss_tag: '',
                            content_api_key: ''
                        });
                        setIsModalOpen(true);
                    }}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Add New Publisher
                </button>
            </div>

            <div className="mb-6">
                <button
                    onClick={handleFetchAllFeeds}
                    disabled={fetchingAll}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-gray-400"
                >
                    {fetchingAll ? 'Fetching All Feeds...' : 'Fetch All Feeds'}
                </button>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setIsEditing(false);
                    setSelectedPublisher(null);
                }}
                title={isEditing ? 'Edit Publisher' : 'Add New Publisher'}
            >
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                    </div>
                    
                    <div>
                        <label className="block text-sm font-medium text-gray-700">RSS URL</label>
                        <input
                            type="url"
                            value={formData.rss_url}
                            onChange={(e) => setFormData(prev => ({ ...prev, rss_url: e.target.value }))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                    </div>
                    
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Logo</label>
                        <div className="mt-1 flex items-center">
                            {formData.logo_url && (
                                <img
                                    src={formData.logo_url}
                                    alt="Logo preview"
                                    className="h-12 w-12 object-contain mr-4"
                                />
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoUpload}
                                className="hidden"
                                id="logo-upload"
                            />
                            <label
                                htmlFor="logo-upload"
                                className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                            >
                                {uploading ? 'Uploading...' : 'Upload Logo'}
                            </label>
                        </div>
                    </div>
                    
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            rows={3}
                        />
                    </div>
                    
                    <div>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={formData.active}
                                onChange={(e) => setFormData(prev => ({ ...prev, active: e.target.checked }))}
                                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                            <span className="ml-2">Active</span>
                        </label>
                    </div>
                    
                    <div className="border-t pt-4 mt-4">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Content Fetch Settings</h3>
                        
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Content Fetch Method</label>
                            <select
                                value={formData.content_fetch_type || ''}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    content_fetch_type: e.target.value as 'rss' | 'api' | null,
                                    // Clear related fields when changing type
                                    content_rss_tag: '',
                                    content_api_key: ''
                                }))}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            >
                                <option value="">No content fetch</option>
                                <option value="rss">Fetch from RSS</option>
                                <option value="api">Fetch with API</option>
                            </select>
                        </div>

                        {formData.content_fetch_type === 'rss' && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">RSS Content Tag</label>
                                <input
                                    type="text"
                                    value={formData.content_rss_tag}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        content_rss_tag: e.target.value
                                    }))}
                                    placeholder="e.g., content:encoded, description"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                />
                                <p className="mt-1 text-sm text-gray-500">
                                    Specify the RSS tag that contains the full content
                                </p>
                            </div>
                        )}

                        {formData.content_fetch_type === 'api' && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">API Key</label>
                                <input
                                    type="text"
                                    value={formData.content_api_key}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        content_api_key: e.target.value
                                    }))}
                                    placeholder="Enter API key"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                />
                            </div>
                        )}
                    </div>
                    
                    <div className="flex justify-end mt-6">
                        <button
                            type="button"
                            onClick={() => setIsModalOpen(false)}
                            className="mr-3 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {isEditing ? 'Update Publisher' : 'Add Publisher'}
                        </button>
                    </div>
                </form>
            </Modal>

            <div className="grid gap-4">
                {publishers.map((publisher) => (
                    <div key={publisher.id} className="bg-white p-4 rounded-lg shadow-md">
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="font-bold text-lg">{publisher.name}</h3>
                                <p className="text-sm text-gray-600">{publisher.rss_url}</p>
                                {publisher.description && (
                                    <p className="text-sm text-gray-600 mt-2">{publisher.description}</p>
                                )}
                                <div className="mt-2">
                                    <span className={`px-2 py-1 rounded-full text-xs ${publisher.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                        {publisher.active ? 'Active' : 'Inactive'}
                                    </span>
                                </div>
                                <button
                                    onClick={() => handleFetchFeeds(publisher.id)}
                                    disabled={fetchingFeeds[publisher.id]}
                                    className="ml-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-gray-400"
                                >
                                    {fetchingFeeds[publisher.id] ? 'Fetching...' : 'Fetch Feeds'}
                                </button>
                            </div>
                            <div>
                                {publisher.logo_url && (
                                    <img
                                        src={publisher.logo_url}
                                        alt={`${publisher.name} logo`}
                                        className="w-16 h-16 object-contain"
                                    />
                                )}
                                <button
                                    onClick={() => handleEdit(publisher)}
                                    className="mt-2 text-blue-500 hover:text-blue-700"
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className="mt-2 text-sm text-gray-600">
                            Content Fetch: {publisher.content_fetch_type ? (
                                <span className="text-green-600">
                                    {publisher.content_fetch_type === 'rss' ? 'RSS' : 'API'}
                                </span>
                            ) : (
                                <span className="text-gray-400">None</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PublisherManagement;