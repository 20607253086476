import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface Log {
    id: number;
    action: string;
    entity_type: string;
    entity_id: number;
    status: string;
    details: string;
    created_at: string;
}

const getStatusStyle = (status: string) => {
    switch (status) {
        case 'success':
            return 'bg-green-100 text-green-800';
        case 'failed':
            return 'bg-red-100 text-red-800';
        case 'partial':
            return 'bg-yellow-100 text-yellow-800';
        case 'pending':
            return 'bg-blue-100 text-blue-800';
        default:
            return 'bg-gray-100 text-gray-800';
    }
};

const formatEntityType = (type: string, id: number) => {
    switch (type) {
        case 'article':
            return `Article #${id}`;
        case 'publisher':
            return `Publisher #${id}`;
        case 'content_fetch':
            return `Content Fetch #${id}`;
        default:
            return `${type} ${id}`;
    }
};

const ActivityLogs: React.FC = () => {
    const { api } = useAuth();
    const [logs, setLogs] = useState<Log[]>([]);
    const [loading, setLoading] = useState(true);

    const fetchLogs = async () => {
        try {
            const response = await api.get('/api/logs');
            setLogs(response.data);
        } catch (error) {
            console.error('Failed to fetch logs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
        const interval = setInterval(fetchLogs, 30000); // Refresh every 30 seconds
        return () => clearInterval(interval);
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <div className="bg-white shadow-md rounded my-6">
            <table className="min-w-full">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Time</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Action</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Entity</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Details</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log) => (
                        <tr key={log.id} className="border-b hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                                {new Date(log.created_at).toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">{log.action}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                                {formatEntityType(log.entity_type, log.entity_id)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                                <span className={`px-2 py-1 rounded ${getStatusStyle(log.status)}`}>
                                    {log.status}
                                </span>
                            </td>
                            <td className="px-6 py-4 text-sm">
                                <div className="max-w-md overflow-hidden text-ellipsis">
                                    {log.details}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ActivityLogs; 