import React from 'react';
import { Outlet, Navigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface ProtectedLayoutProps {
    navigation?: Array<{
        name: string;
        href: string;
        requiresAdmin?: boolean;
    }>;
}

const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ navigation }) => {
    const { isAuthenticated, logout } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    const isActive = (path: string) => {
        return location.pathname === path ? 'bg-indigo-800' : '';
    };

    return (
        <div className="flex h-screen">
            {/* Sidebar */}
            <div className="w-64 bg-indigo-900 text-white">
                <div className="p-4">
                    <h1 className="text-xl font-bold">Admin Panel</h1>
                </div>
                <nav className="mt-4">
                    <Link to="/admin" className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin')}`}>
                        Dashboard
                    </Link>
                    <Link to="/admin/publishers" className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin/publishers')}`}>
                        Publishers
                    </Link>
                    <Link to="/admin/articles" className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin/articles')}`}>
                        Articles
                    </Link>
                    <Link 
                        to="/admin/articles_management" 
                        className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin/articles_management')}`}
                    >
                        Articles Management
                    </Link>
                    <Link to="/admin/tags" className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin/tags')}`}>
                        Tags
                    </Link>
                    <Link to="/admin/users" className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin/users')}`}>
                        Users
                    </Link>
                    <Link to="/admin/logs" className={`block px-4 py-2 hover:bg-indigo-800 ${isActive('/admin/logs')}`}>
                        Activity Logs
                    </Link>
                </nav>
                <div className="absolute bottom-0 w-64 p-4">
                    <button
                        onClick={logout}
                        className="w-full px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded"
                    >
                        Logout
                    </button>
                </div>
            </div>

            {/* Main content */}
            <div className="flex-1 overflow-auto">
                <div className="p-8">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default ProtectedLayout; 