import React from 'react';

interface MarketData {
    symbol: string;
    price: number;
    change: number;
    volume: string;
}

const MarketOverview: React.FC = () => {
    // This would typically come from an API
    const marketData: MarketData[] = [
        {
            symbol: 'Nifty 50',
            price: 22250.78,
            change: -71.55,
            volume: '1.2B'
        },
        // Add more market data as needed
    ];

    return (
        <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Market Overview</h2>
            <div className="space-y-4">
                {marketData.map((item) => (
                    <div key={item.symbol} className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <span className="font-medium">{item.symbol}</span>
                            <span className="text-2xl font-semibold">{item.price.toLocaleString()}</span>
                        </div>
                        <div className="flex items-center space-x-4">
                            <span className={`${
                                item.change >= 0 ? 'text-green-600' : 'text-red-600'
                            }`}>
                                {item.change >= 0 ? '+' : ''}{item.change.toFixed(2)} 
                                ({((item.change / item.price) * 100).toFixed(2)}%)
                            </span>
                            <span className="text-gray-500">Vol: {item.volume}</span>
                        </div>
                    </div>
                ))}
            </div>
            
            {/* Add Chart Component Here */}
            <div className="mt-6 h-64 bg-gray-50 rounded flex items-center justify-center">
                <span className="text-gray-400">Chart Coming Soon</span>
            </div>
        </div>
    );
};

export default MarketOverview; 