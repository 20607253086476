import React from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { NewsItem } from '../../types/news';

interface NewsTableProps {
    news: NewsItem[];
    loading: boolean;
    onLoadMore: () => void;
    hasMore: boolean;
}

const NewsTable: React.FC<NewsTableProps> = ({ news, loading, onLoadMore, hasMore }) => {
    const formatTimestamp = (timestamp: string) => {
        const date = new Date(timestamp);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const hours = diff / (1000 * 60 * 60);

        if (hours < 24) {
            return formatDistanceToNow(date, { addSuffix: true });
        }
        return format(date, 'MMM d, yyyy HH:mm');
    };

    const getArticleUrl = (url: string) => {
        const baseUrl = url.includes('?') ? `${url}&` : `${url}?`;
        return `${baseUrl}ref=coinscoop.org`;
    };

    if (loading) {
        return (
            <div className="animate-pulse">
                {[...Array(5)].map((_, i) => (
                    <div key={i} className="h-16 bg-gray-100 mb-2 rounded"></div>
                ))}
            </div>
        );
    }

    return (
        <div>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">News</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Freshness</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sentiment</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Coins</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">AI Summary</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {news.map((item) => (
                            <tr key={item.id} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {formatTimestamp(item.timestamp)}
                                </td>
                                <td className="px-6 py-4">
                                    <div className="space-y-1">
                                        <a 
                                            href={getArticleUrl(item.url)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-sm font-medium text-gray-900 hover:text-blue-600"
                                        >
                                            {item.headline}
                                        </a>
                                        <div className="flex items-center space-x-2">
                                            {item.publisher.logo_url && (
                                                <img 
                                                    src={item.publisher.logo_url} 
                                                    alt={item.publisher.name}
                                                    className="h-4 w-4 object-contain"
                                                />
                                            )}
                                            <span className="text-xs text-gray-500">
                                                By {item.publisher.name}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {item.freshness}%
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                                        ${item.sentiment === 'positive' ? 'bg-green-100 text-green-800' : 
                                        item.sentiment === 'negative' ? 'bg-red-100 text-red-800' : 
                                        'bg-gray-100 text-gray-800'}`}>
                                        {item.sentiment}
                                    </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {item.coins.join(', ')}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500">
                                    <button className="text-indigo-600 hover:text-indigo-900">
                                        View Summary
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            {hasMore && (
                <div className="mt-4 flex justify-center">
                    <button 
                        onClick={onLoadMore}
                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        Load More
                    </button>
                </div>
            )}
        </div>
    );
};

export default NewsTable; 